import React, { useState } from 'react';
import SubmitButton from './SubmitButton';
import Info from './Info';
import Fields from './Fields';
import EmployeeManager from './EmployeeManager';
import WarningIcon from './icons/WarningIcon';
import axios from 'axios';

function Main() {
  const [spot, setSpot] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const handleSpotChange = (data) => {
    setSpot(data);
  };

  const handleEmployeesChange = (data) => {
    setEmployees(data);
  };

  const postData = async () => {
    if (spot === null) {
      setErrorMessage('Кажется, ты забыл указать спот!');
      return;
    } else if (employees.length === 0) {
      setErrorMessage('Кажется, ты забыл добавить сотрудников!');
      return;
    }

    for (const employee of employees) {
      if (employee.name === '' || employee.photo == null) {
        setErrorMessage('Кажется, ты забыл добавить имя или фото сотрудника!');
        return;
      }
    }

    let form = new FormData();
    form.append('spotName', spot.name.toUpperCase());
    form.append('spotAddress', spot.address.toUpperCase());

    let employeeList = JSON.parse(JSON.stringify(employees));

    employeeList = employeeList.map(obj => {
      delete obj['fullPhoto'];
      delete obj['id'];
      obj['photo'] = obj['photo'].split('base64,')[1];
      obj['name'] = obj['name'].toUpperCase();
      return obj;
    });

    form.append('employees', JSON.stringify(employeeList));

    try {
      await axios.post('https://badge.surfcoffee.ru/api/upload', form);
      console.log('Successfully uploaded!');
      setSubmissionSuccess(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="main">
      {!submissionSuccess ? (
        <>
          <h1>Конструктор бейджей</h1>
          <img
            className={'main-img'}
            src={require('../images/mainbadge.png')}
            alt={'Badge Example'}
            style={{ marginBottom: '30px' }}
          />
          <Fields onSpotData={handleSpotChange} onAddressData={handleSpotChange} />
          <hr />
          <EmployeeManager onEmployeeData={handleEmployeesChange} />
          <Info />
          <hr />
          {errorMessage && <p className="error-message"><WarningIcon />{errorMessage}</p>}
          <SubmitButton onClick={postData} />
        </>
      ) : (
        <div className="finish-screen">
          <h2 style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>Спасибо!</h2>
          <p style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>Мы уже понесли бейджики в печать, положим их в Ваш заказ, как только они будут
            готовы.</p>
        </div>
      )}
    </div>
  );
}

export default Main;
